import React, { useEffect, useState } from 'react'
import { ReactComponent as Logo } from '../../assets/icons/logo.svg'
import tw, { styled, theme } from 'twin.macro'
import { Inline, Stack } from '../common/components/Spacing'
import { Alignments, ComponentVariants, Sizes } from '../common/constants'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { DropdownItem, DropdownMenu } from '../common/components/DropdownMenu'
import { Button } from '../common/components/Buttons'
import { NavigationLink } from './components/NavigationLink'
import { useBookDemoModal } from '../../context'
import { ReactComponent as Menu } from '../../assets/icons/menu.svg'
import { useIsMobile } from '../common/hooks'
import { TextBig } from '../common/styled'
import { motion, useAnimation } from 'framer-motion'
import { ReactComponent as Close2 } from '../../assets/icons/close2.svg'
import { getCurrentLocale } from '../../config/locales'
import { prismicClient } from '../../config/prismicClient'
import { useInitialNavigationAnimation } from './hooks'

const Wrapper = tw.div`fixed top-0 left-0 right-0 z-20 pt-[50px] pb-[20px] bg-zevoyBlueBlack`
const Inner = tw.div` w-[1200px] mx-auto`
const LogoIcon = tw(Logo)`w-[29px] h-[28px]`
const ExternalNavigationLink = styled.a`
  ${tw`text-sm relative block`}

  &::after {
    ${tw`absolute left-0 h-[1px] rounded-md transition-all duration-500 ease-in-out`}
    content: "";
    background: linear-gradient(
      to right,
      ${theme('colors.electricViolet')},
      ${theme('colors.vividBlue')}
    );
    bottom: -7px;
    width: 0;
  }

  &:hover::after {
    ${tw`w-full`}
  }
`
const LogoWrapper = tw(NavLink)`flex items-center`

const MobileWrapper = tw.div`fixed top-0 left-0 right-0 z-30 bg-zevoyBlueBlack  p-[25px] h-[78px] box-border`
const MenuButton = tw.div`absolute top-5 right-5 cursor-pointer`
type MobileMenuOverlayProps = {
  isOpen: boolean
}
const MobileMenuOverlay = styled(motion.div)<MobileMenuOverlayProps>`
  ${tw`fixed top-0 left-0 right-0 bottom-0 bg-zevoyBlueBlack z-40 px-[25px]`}
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`

const CloseWrapper = styled.div`
  margin-top: 15px;

  @media (min-height: 668px) and (max-height: 754px) {
    margin-top: calc(15px + (17px * ((100vh - 668px) / (754 - 668))));
  }

  @media (min-height: 755px) and (max-height: 843px) {
    margin-top: calc(32px + (16px * ((100vh - 755px) / (843 - 755))));
  }

  @media (min-height: 844px) {
    margin-top: 48px;
  }
`

const CloseButton = tw.div`text-[16px] cursor-pointer`
const MenuIcon = tw(Menu)`fill-zevoyWhite w-[12px] h-[12px]`

const MobileNavs1 = tw.div`mt-[45px] mb-[32px]`
const MobileNavs2 = tw.div`mb-[25px]`
const MobileNavs3 = styled.div``
const MobileNavigationBig = styled(TextBig)`
  ${tw`text-[40px] leading-[132%]`};
  @media (max-height: 750px) {
    font-size: 32px;
  }
`
const MobileNavigationSmall = tw(TextBig)`text-[24px] leading-[145%] -tracking-[1px]`

const BottomWrapper = tw(motion.div)`absolute bottom-[50px] -left-[20px] w-full`

export const Navigation = () => {
  const isMobile = useIsMobile()
  const { locale } = useParams()
  const { pathname } = useLocation()
  const { setBookDemoModalOpen } = useBookDemoModal()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [prismicData, setPrismicData] = useState<any>(null)

  const mobileOverlayControls = useAnimation()
  const mobileHomeLinkControls = useAnimation()
  const mobileProductLinkControls = useAnimation()
  const mobilePricingLinkControls = useAnimation()
  const mobileForMerchantsLinkControls = useAnimation()
  const mobileCustomersLinkControls = useAnimation()
  const mobileContactLinkControls = useAnimation()

  const mobileExpensesLinkControls = useAnimation()
  const mobileSubscriptionsLinkControls = useAnimation()
  const mobileClaimsLinkControls = useAnimation()
  const mobileBenefitsLinkControls = useAnimation()

  const mobileBottomSectionControls = useAnimation()
  const rotateControls = useAnimation()

  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '')
      const prismicLocale = localeData.prismicLocale

      const homePage = await prismicClient.getSingle('navigation_new', {
        lang: prismicLocale,
      })
      setPrismicData(homePage)
    }
    getPrismicData()
  }, [locale])

  useInitialNavigationAnimation({
    isMenuOpen,
    mobileOverlayControls,
    mobileHomeLinkControls,
    mobileProductLinkControls,
    mobileExpensesLinkControls,
    mobileSubscriptionsLinkControls,
    mobileClaimsLinkControls,
    mobileBenefitsLinkControls,
    mobileForMerchantsLinkControls,
    mobilePricingLinkControls,
    mobileCustomersLinkControls,
    mobileContactLinkControls,
    mobileBottomSectionControls,
  })

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflowY = 'hidden'
      return
    }
    document.body.style.overflowY = 'auto'
  }, [isMobile, isMenuOpen])

  const translations = {
    home: prismicData?.data?.home?.[0]?.text,
    solution: prismicData?.data?.solution?.[0]?.text,
    expenses: prismicData?.data?.expenses?.[0]?.text,
    subscriptions: prismicData?.data?.subscriptions?.[0]?.text,
    claims: prismicData?.data?.claims?.[0]?.text,
    benefits: prismicData?.data?.benefits?.[0]?.text,
    forMerchants: prismicData?.data?.for_merchants?.[0]?.text,
    pricing: prismicData?.data?.pricing?.[0]?.text,
    customers: prismicData?.data?.customers?.[0]?.text,
    contact: prismicData?.data?.contact?.[0]?.text,
    language: prismicData?.data?.language?.[0]?.text,
    fi: prismicData?.data?.fi?.[0]?.text,
    fi_short: prismicData?.data?.fi_short?.[0]?.text,
    sv: prismicData?.data?.sv?.[0]?.text,
    sv_short: prismicData?.data?.sv_short?.[0]?.text,
    en: prismicData?.data?.en?.[0]?.text,
    en_short: prismicData?.data?.en_short?.[0]?.text,
    login: prismicData?.data?.login?.[0]?.text,
    demo: prismicData?.data?.demo?.[0]?.text,
    menu: prismicData?.data?.menu?.[0]?.text,
    close: prismicData?.data?.close?.[0]?.text,
    helpdesk: prismicData?.data?.helpdesk?.[0]?.text,
    helpdesk_link: prismicData?.data?.helpdesk_link,
  }

  const productItems: DropdownItem[] = [
    {
      label: translations.expenses,
      href: `/${locale}/expenses`,
    },
    {
      label: translations.subscriptions,
      href: `/${locale}/subscriptions`,
    },
    {
      label: translations.claims,
      href: `/${locale}/claims`,
    },
    {
      label: translations.benefits,
      href: `/${locale}/benefits`,
    },
  ]

  const getLocaleHref = (newLocale: string) => pathname.replace(/\/[a-z]{2}\/?/, `/${newLocale}/`)
  const localeItems: DropdownItem[] = [
    {
      label: translations.en,
      labelShort: translations.en_short,
      href: getLocaleHref('en'),
    },
    {
      label: translations.fi,
      labelShort: translations.fi_short,
      href: getLocaleHref('fi'),
    },
    {
      label: translations.sv,
      labelShort: translations.sv_short,
      href: getLocaleHref('sv'),
    },
  ]

  const closeModal = async () => {
    await mobileOverlayControls.start({ opacity: 0, transition: { duration: 0.3 } })
    setIsMenuOpen(false)
  }

  if (isMobile) {
    return (
      <>
        <MobileWrapper>
          <Inline align={Alignments.Between}>
            <LogoWrapper to={`/${locale}`}>
              <LogoIcon />
            </LogoWrapper>
            <MenuButton onClick={() => setIsMenuOpen(true)}>
              <Inline>
                <MenuIcon />
                <div>{translations.menu}</div>
              </Inline>
            </MenuButton>
          </Inline>
        </MobileWrapper>
        <MobileMenuOverlay
          isOpen={isMenuOpen}
          animate={mobileOverlayControls}
          initial={{ opacity: 0 }}
        >
          <MobileNavs1>
            <NavLink to={`/${locale}`} onClick={closeModal}>
              <MobileNavigationBig animate={mobileHomeLinkControls} initial={{ opacity: 0 }}>
                {translations.home}
              </MobileNavigationBig>
            </NavLink>
            <NavLink to={`/${locale}/expenses`} onClick={closeModal}>
              <MobileNavigationBig animate={mobileProductLinkControls} initial={{ opacity: 0 }}>
                {translations.solution}
              </MobileNavigationBig>
            </NavLink>
          </MobileNavs1>
          <MobileNavs2>
            <NavLink to={`/${locale}/expenses`} onClick={closeModal}>
              <MobileNavigationSmall animate={mobileExpensesLinkControls} initial={{ opacity: 0 }}>
                {translations.expenses}
              </MobileNavigationSmall>
            </NavLink>
            <NavLink to={`/${locale}/subscriptions`} onClick={closeModal}>
              <MobileNavigationSmall
                animate={mobileSubscriptionsLinkControls}
                initial={{ opacity: 0 }}
              >
                {translations.subscriptions}
              </MobileNavigationSmall>
            </NavLink>
            <NavLink to={`/${locale}/claims`} onClick={closeModal}>
              <MobileNavigationSmall animate={mobileClaimsLinkControls} initial={{ opacity: 0 }}>
                {translations.claims}
              </MobileNavigationSmall>
            </NavLink>
            <NavLink to={`/${locale}/benefits`} onClick={closeModal}>
              <MobileNavigationSmall animate={mobileBenefitsLinkControls} initial={{ opacity: 0 }}>
                {translations.benefits}
              </MobileNavigationSmall>
            </NavLink>
          </MobileNavs2>
          <MobileNavs3>
            <NavLink to={`/${locale}/merchants`} onClick={closeModal}>
              <MobileNavigationBig
                animate={mobileForMerchantsLinkControls}
                initial={{ opacity: 0 }}
              >
                {translations.forMerchants}
              </MobileNavigationBig>
            </NavLink>
            <NavLink to={`/${locale}/pricing`} onClick={closeModal}>
              <MobileNavigationBig animate={mobilePricingLinkControls} initial={{ opacity: 0 }}>
                {translations.pricing}
              </MobileNavigationBig>
            </NavLink>
            <NavLink to={`/${locale}/customers`} onClick={closeModal}>
              <MobileNavigationBig animate={mobileCustomersLinkControls} initial={{ opacity: 0 }}>
                {translations.customers}
              </MobileNavigationBig>
            </NavLink>
            <NavLink to={`/${locale}/contact`} onClick={closeModal}>
              <MobileNavigationBig animate={mobileContactLinkControls} initial={{ opacity: 0 }}>
                {translations.contact}
              </MobileNavigationBig>
            </NavLink>
          </MobileNavs3>
          <BottomWrapper animate={mobileBottomSectionControls} initial={{ opacity: 0 }}>
            <Stack align={Alignments.Center}>
              <Stack gap={5} align={Alignments.Center}>
                <Inline>
                  <NavLink to={getLocaleHref('en')}>
                    <Button
                      style={{ padding: '7px 10px', lineHeight: 'normal', fontSize: '14px' }}
                      size={Sizes.Small}
                      variant={
                        locale === 'en' ? ComponentVariants.Light : ComponentVariants.Borderless
                      }
                    >
                      {translations.en_short}
                    </Button>
                  </NavLink>
                  <NavLink to={getLocaleHref('fi')}>
                    <Button
                      style={{ padding: '7px 10px', lineHeight: 'normal', fontSize: '14px' }}
                      size={Sizes.Small}
                      variant={
                        locale === 'fi' ? ComponentVariants.Light : ComponentVariants.Borderless
                      }
                    >
                      {translations.fi_short}
                    </Button>
                  </NavLink>
                  <NavLink to={getLocaleHref('sv')}>
                    <Button
                      style={{ padding: '7px 10px', lineHeight: 'normal', fontSize: '14px' }}
                      size={Sizes.Small}
                      variant={
                        locale === 'sv' ? ComponentVariants.Light : ComponentVariants.Borderless
                      }
                    >
                      {translations.sv_short}
                    </Button>
                  </NavLink>
                </Inline>
                <Inline>
                  <Button
                    size={Sizes.Small}
                    variant={ComponentVariants.Light}
                    onClick={() => {
                      window.open('https://hub.zevoy.com/login', '_blank')
                    }}
                  >
                    {translations.login}
                  </Button>
                  <Button
                    size={Sizes.Small}
                    variant={ComponentVariants.Light}
                    onClick={() => {
                      window.open(
                        `${translations.helpdesk_link?.url}`,
                        `${translations.helpdesk_link?.target}`,
                      )
                    }}
                  >
                    {translations.helpdesk}
                  </Button>
                  <Button
                    size={Sizes.Small}
                    variant={ComponentVariants.Primary}
                    onClick={() => {
                      setBookDemoModalOpen(true)
                    }}
                  >
                    {translations.demo}
                  </Button>
                </Inline>
              </Stack>

              <CloseWrapper
                onClick={() => {
                  closeModal()
                }}
              >
                <Inline>
                  <motion.div animate={rotateControls}>
                    <Close2 />
                  </motion.div>
                  <CloseButton>{translations.close}</CloseButton>
                </Inline>
              </CloseWrapper>
            </Stack>
          </BottomWrapper>
        </MobileMenuOverlay>
      </>
    )
  }

  if (!prismicData) {
    return null
  }
  return (
    <Wrapper>
      <Inner>
        <Inline verticalalign={Alignments.Center} align={Alignments.Between}>
          <Inline verticalalign={Alignments.Center} align={Alignments.Between} gap={10}>
            <LogoWrapper to={`/${locale}`}>
              <LogoIcon />
            </LogoWrapper>
            <nav>
              <Inline verticalalign={Alignments.Center} gap={7.5}>
                {locale && (
                  <DropdownMenu
                    items={productItems}
                    label={translations.solution}
                    selected={
                      pathname.includes(`/${locale}/expenses`) ||
                      pathname.includes(`/${locale}/claims`) ||
                      pathname.includes(`/${locale}/subscriptions`) ||
                      pathname.includes(`/${locale}/benefits`)
                    }
                  />
                )}
                <NavigationLink
                  to={`/${locale}/merchants`}
                  label={translations.forMerchants}
                  selected={pathname.includes(`/${locale}/merchants`)}
                />
                <NavigationLink
                  to={`/${locale}/pricing`}
                  label={translations.pricing}
                  selected={pathname.includes(`/${locale}/pricing`)}
                />
                <NavigationLink
                  to={`/${locale}/customers`}
                  label={translations.customers}
                  selected={pathname.includes(`/${locale}/customers`)}
                />
                <NavigationLink
                  to={`/${locale}/contact`}
                  label={translations.contact}
                  selected={pathname.includes(`/${locale}/contact`)}
                />
              </Inline>
            </nav>
          </Inline>
          <Inline verticalalign={Alignments.Center} gap={7.5}>
            {locale && (
              <DropdownMenu
                items={localeItems}
                label={locale.toUpperCase()}
                menuLabel={translations.language}
              />
            )}
            <Inline verticalalign={Alignments.Center}>
              <Button
                size={Sizes.Small}
                variant={ComponentVariants.Light}
                onClick={() => {
                  window.open('https://hub.zevoy.com/login', '_blank')
                }}
              >
                {translations.login}
              </Button>
              <Button
                size={Sizes.Small}
                variant={ComponentVariants.Light}
                onClick={() => {
                  window.open(
                    `${translations.helpdesk_link?.url}`,
                    `${translations.helpdesk_link?.target}`,
                  )
                }}
              >
                {translations.helpdesk}
              </Button>
              <Button
                size={Sizes.Small}
                variant={ComponentVariants.Primary}
                onClick={() => {
                  setBookDemoModalOpen(true)
                }}
              >
                {translations.demo}
              </Button>
            </Inline>
          </Inline>
        </Inline>
      </Inner>
    </Wrapper>
  )
}
